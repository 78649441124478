<template>
  <Nav :content="content" ref="nav"/>

  <div class="container" style="min-height: 100vh">
    <div id="About" class="spacer"></div>
    <AboutUs :text="content['About Us']" />
    <div id="Meet" class="spacer"></div>
    <MeetTheTeam :members="content['Meet the Team']" :default_profile="content['default_profile']" />
    <div id="Sponsors" class="spacer"></div>
    <Sponsors :sponsors="content['Sponsors']" />
    <div id="Contact" class="spacer"></div>
    <Contact :links="content['Contact Us']" />
    <div class="spacer"></div>
  </div>

  <div class="" id="footer">
    Site Design / Logo © 2022 York Aerospace and Rocketry
  </div>
</template>

<script>
import Nav from "./components/Nav.vue";
import AboutUs from "./components/AboutUs.vue";
import MeetTheTeam from "./components/MeetTheTeam.vue";
import Sponsors from "./components/Sponsors.vue";
import Contact from "./components/Contact.vue";

import Content from "./content/main.json";

export default {
  name: "Index",
  components: {
    Nav,
    AboutUs,
    MeetTheTeam,
    Sponsors,
    Contact,
  },
  mounted() {
    this.$refs.nav.resizeSpacer();
  },
  data() {
    return {
      content: Content,
    };
  },
};
</script>

<style>

#app {
  font-family: Nunito Sans, sans-serif;
  text-align: center;
  color: var(--main-text);
  background-color: var(--main-bg);
}

.spacer {
  height: 4rem;
  display: block;
  position: relative;
  top: -100px;
  visibility: hidden;
}

#banner {
  max-height: 50vh;
}

#footer {
  background-color: var(--accent);
  color: var(--accent-text);
  text-align: right;
  padding: 1rem;
}

h1 {
  font-size: 3em;
}
</style>
